/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faTruckFast, faStar, faGears, faWindowRestore, faPlugCircleCheck, faHatWizard} from "@fortawesome/free-solid-svg-icons";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2"
  }, _provideComponents(), props.components), {Hero, ButtonLink, Container, Row, IconCard} = _components;
  if (!ButtonLink) _missingMdxReference("ButtonLink", true);
  if (!Container) _missingMdxReference("Container", true);
  if (!Hero) _missingMdxReference("Hero", true);
  if (!IconCard) _missingMdxReference("IconCard", true);
  if (!Row) _missingMdxReference("Row", true);
  return React.createElement(React.Fragment, null, React.createElement(Hero, {
    title: "Yhden hengen ohjelmistotalo.",
    subtitle: "IT-ratkaisut aloittaville yrityksille, digiloikkaajille ja isommille\nkorporaatioille.",
    cta: React.createElement(ButtonLink, {
      to: "/palvelut/aloittaville-yrityksille",
      text: "Lue lisää",
      noIcon: true
    })
  }), "\n", React.createElement(Container, null, React.createElement(_components.h1, null, "Yhden hengen ohjelmistotalo?"), React.createElement(_components.p, null, "No jaa. Oikeastaan olen freelance-koodari ja monipuolinen IT-osaaja, joka ei ole\nerikoistunut mihinkään tiettyyn teknologiaan."), React.createElement(_components.h2, null, "Eli osaat vähän kaikkea mutta et mitään kunnolla™?"), React.createElement(_components.p, null, "Niin no, olen IT-insinööri. Ohjelmistokehitys on kuitenkin enimmäkseen\nongelmanratkaisua, ja suuri osa ongelmista on ratkaistu jo moneen kertaan.\nNäiden ratkaisujen kaivaminen esiin on se juttu."), React.createElement(_components.p, null, "Lähes aina tilanne on kuitenkin se, etteivät valmiit ratkaisut olekaan valmiita,\nja niitä joutuu muokkaamaan kulloiseenkin tarpeeseen sopiviksi. Joskus käy jopa\nniin, että käsillä oleva ongelma on niin omalaatuinen, ettei mikään valmis\nratkaisu ole lähelläkään sitä mitä pitäisi."), React.createElement(_components.p, null, "Ohjelmistokehityksen perusasioiden vahva hallinta sekä laaja tietämys\nerilaisista teknologioista ja valmiista härpäkkeistä mahdollistaa sen, että\nongelmaan kuin ongelmaan saadaan juuri oikeanlainen ratkaisukokonaisuus\nnopeasti, luotettavasti ja laadukkaasti*."), React.createElement("small", {
    className: "d-block"
  }, React.createElement(_components.p, null, "*Suhteellisia käsitteitä, jotka usein riippuvat suoraan esimerkiksi\ntoimeksiantajan maksuvalmiudesta ja aikataulujen realistisuudesta.")), React.createElement(Row, {
    className: "flex-lg-nowrap justify-content-center"
  }, React.createElement(IconCard, {
    icon: faTruckFast,
    className: "bg-transparent col-sm-12 col-lg-4",
    title: "Nopee",
    body: "Jos nettisivusi on oltava pystyssä viimeistään toissapäivänä,\netsitään puolivalmis pohja, lisätään sisältö ja laitetaan\npakettiin."
  }), React.createElement(IconCard, {
    icon: faStar,
    className: "bg-transparent col-sm-12 col-lg-4",
    title: "Upee",
    body: "Jos nettisivusi pitää olla jopa hieno tai helposti\nmuokattavissa, käytetään vähän enemmän aikaa ja tehdään siitä\njust sellainen kuin pitää."
  }), React.createElement(IconCard, {
    icon: faGears,
    className: "bg-transparent col-sm-12 col-lg-4",
    title: "Toimiva",
    body: "Jos nettisivusi pitää tehdä muutakin kuin näyttää nätiltä,\nvalitaan sopiva pilvipalveluntarjoaja ja rakennetaan ihan oikea\näppi!"
  })), React.createElement(ButtonLink, {
    to: "/palvelut/aloittaville-yrityksille",
    text: "Aloittaville yrityksille",
    center: true
  }), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement(_components.h2, null, "Siis pelkkiä nettijuttuja, niinkö?"), React.createElement(_components.p, null, "Vaikka nettijutuille onkin tänä päivänä eniten kysyntää ja kaiken pitää nykyään\ntoimia selaimessa, jotkut ratkaisut vaativat edelleen ohjelmistojen tai niiden\nosien toteuttamisen natiiveina työpöytä- tai mobiilisovelluksina."), React.createElement(_components.p, null, "Perinteisen sovelluskehityksen lisäksi tarvitaan myös erilaisia (ja joskus\nerikoisia) käyttöjärjestelmä- ja laitteistotason ratkaisuja. Kaikessa on\ntietenkin myös otettava huomioon monenlaisia käytettävyyteen, ylläpitoon,\ntietoturvaan ja kustannustehokkuuteen liittyviä seikkoja*."), React.createElement("small", {
    className: "d-block"
  }, React.createElement(_components.p, null, "*Tähänkin varmasti liittyy jokin pieni präntti.")), React.createElement(Row, {
    className: "flex-lg-nowrap justify-content-center"
  }, React.createElement(IconCard, {
    icon: faWindowRestore,
    className: "bg-transparent col-sm-12 col-lg-4",
    title: "Äpit",
    body: "Sovellukset selaimille, Linuxeille, Windowsille ja\nmobiililaitteille."
  }), React.createElement(IconCard, {
    icon: faPlugCircleCheck,
    className: "bg-transparent col-sm-12 col-lg-4",
    title: "APIt",
    body: "Ohjelmointi- ja datarajapinnat, integraatiot ja näihin liittyvät\nkäyttöliittymäkomponentit."
  }), React.createElement(IconCard, {
    icon: faHatWizard,
    className: "bg-transparent col-sm-12 col-lg-4",
    title: "Viritykset",
    body: "Skriptit, vimpaimet, widgetit, gadgetit, häksit ja hässäkät."
  })), React.createElement("div", {
    className: "w-100 d-flex justify-content-center gap-4 flex-wrap"
  }, React.createElement(ButtonLink, {
    to: "/palvelut/digiloikkaajille",
    text: "Digiloikkaajille"
  }), React.createElement(ButtonLink, {
    to: "/palvelut/it-korporaatioille",
    text: "IT-korporaatioille"
  })), React.createElement("br"), React.createElement("br"), React.createElement("br"), React.createElement(_components.h2, null, "Riittää jo se ohjelmistotalo-löpinä, onko sinulla ratkaisu ongelmaani?"), React.createElement(_components.p, null, "Jotta ongelmasi saadaan ratkaistua kestävän kehityksen periaatteita noudattaen\nketterästi ja ohjelmistoteknisesti sekä eettisesti oikealla ja\nasiakaslähtöisellä, vallankumouksellisella ja uudenaikaisella tavalla (ottaen\nhuomioon myös muut taikasanat), tulee meidän yhdessä ymmärtää ongelman luonne,\nlaajuus ja syvin olemus keskustelemalla ja sopimalla perinpohjaisesti ja\nvuorovaikutteisesti ongelmaan liittyvistä vaatimuksista sekä laadullisista,\ntaloudellisista ja aikataulullisista seikoista."), React.createElement(_components.p, null, "Sitten tehdään se äppi ja katsotaan että se myös toimii."), React.createElement(ButtonLink, {
    to: "/palvelut/aloittaville-yrityksille",
    text: "Nyt sinne palveluihin jo!",
    center: true
  })));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
